<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'Home',
    title: "Dumbar Law Home",
    components: {
      HelloWorld,
    },
  }
</script>
