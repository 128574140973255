import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PracticeAreas from '../views/PracticeAreas.vue'
import Bio from '../views/Bio.vue'
import ContactUs from '../views/ContactUs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/practice-areas',
    name: 'Practice Areas',
    component: PracticeAreas
  },
  {
    path: '/bio',
    name: 'Bio',
    component: Bio
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUs
  }

  
]

const router = new VueRouter({
  routes
})

export default router
