<template>
  <v-container fluid>
    <v-row>
      <v-container>
        <v-row>
          <v-col class="mb-5" cols="12">
            <h2 class="text-h3 font-weight-bold mb-3">
              Extensive Experience
            </h2>
          </v-col>
          <v-col cols="12" v-for="item in experience" :key="item.practiceArea">
            <v-card color="">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="8" sm="6" md="4">
                    <v-img
                      :src="require('@/assets/svg/' + item.img)"
                      min-width="200px"
                      class="ma-6"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="8">
                    <v-card-title
                      class="text-capitalize text-h4 justify-start "
                    >
                      {{ item.practiceArea }}
                    </v-card-title>
                    <span v-html="item.blurb" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import jsonPracticeAreas from "@/assets/json/practice-areas.json";
export default {
  name: "PracticeAreas",
  title: "Dumbar Law Practice Areas",

  data: () => ({
    experience: jsonPracticeAreas,
  }),
};
</script>
