<template>
  <v-container fluid px-0 pt-0>
    <v-row no-gutters>
      <v-col>
        <video-bg
          :sources="['/assets/mp4/AdobeStock_116226963_Video_HD_Preview.mp4']"
          img="someURL"
        >
          <v-container fluid fill-height>
            <v-row align="center" justify="center">
              <v-col class="text-center" cols="11" sm="8" md="6" lg="4">
                <v-card color="transparent">
                  <v-img
                    gradient="337deg, rgba(191,172,170,0.639093137254902) 0%, rgba(225,225,212,1) 100%"
                  >
                    <v-card-text>
                      <v-avatar
                        color="paper"
                        size="150"
                        class="mb-6 elevation-5"
                      >
                        <v-avatar color="transparent" size="90" tile>
                          <v-img
                            :src="
                              require('../assets/svg/logo-dumbarlaw-primary.svg')
                            "
                            contain
                            class="pa-6"
                          />
                        </v-avatar>
                      </v-avatar>
                      <h2 class="text-h3">Our Mission</h2>
                      <h4 class="subtitle-1">
                        The Dumbar Law Center was created to assist homeowners,
                        businesses and investors with real estate and commercial
                        litigation matters. At The Dumbar Law Center, our
                        clients are our top priority and we look forward to
                        representing your interests in the near future.
                      </h4>
                      <p class="mb-0 subtitle-1 font-weight-bold">
                        Please contact us today to schedule a free consultation.
                      </p>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="success"
                        block
                        tile
                        depressed
                        to="/contact-us"
                      >
                        Contact Us
                      </v-btn>
                    </v-card-actions>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </video-bg>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center" align="center" class="dumbar_dark">
      <v-col cols="10" md="8">
        <v-card dark flat color="transparent">
          <v-card-text>
            <span class="text-h3">
              <v-icon color="dumbar_gray" large>mdi-format-quote-open</v-icon>
              We are committed to competent and excellent representation for our
              clients...
              <v-icon color="dumbar_gray" large>
                mdi-format-quote-close
              </v-icon>
              <small class="dumbar_gray--text text-h6">
                -- Sahr Dumbar, Esq.
              </small>
            </span>
            <br />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="success" small tile depressed to="/bio">
              Meet Sahr Dumbar
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="auto">
        <v-img width="200" src="@/assets/img/photo-dumbar.png" />
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-container px-6>
        <v-row justify="center">
          <v-col class="mb-5" cols="12">
            <h2 class="text-h3 font-weight-bold mb-3">
              Extensive Experience
            </h2>
          </v-col>
          <v-col cols="12" md="6" lg="4" v-for="item in experience" :key="item.practiceArea">
            <v-card color="">
              <v-card-title class="text-capitalize text-h4 justify-center">
                {{ item.practiceArea }}
              </v-card-title>
              <v-list color="transparent">
                <template v-for="(bullet, index) in item.bullets">
                  <v-list-item :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ bullet }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    v-if="index < item.bullets.length - 1"
                    :key="`${index}-divider`"
                  ></v-divider>
                </template>
              </v-list>
              <v-card-actions>
                <v-btn text block color="link" to="/practice-areas"
                  >Learn More</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3 ">
          Serving the Tri-County Area
        </h2>

        <p>Miami-Dade County, Broward County, West Palm Beach County</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jsonPracticeAreas from "@/assets/json/practice-areas.json";
import VideoBg from "vue-videobg";

export default {
  name: "HelloWorld",
  components: { VideoBg },
  data: () => ({
    experience: jsonPracticeAreas,
  }),
};
</script>
