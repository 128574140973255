<template>
  <v-container fluid>
    <v-row>
      <v-container>
        <v-row>
          <v-col class="mb-5" cols="12">
            <h2 class="text-h3 font-weight-bold mb-3">
              Contact Us
            </h2>
          </v-col>
          <v-col cols="12" sm="6" md="5" lg="4">
            <v-card tile class="mx-auto pt-5">
              <v-img
                max-height="150"
                contain
                src="@/assets/svg/logo-dumbarlaw-primary.svg"
              />

              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">
                      mdi-phone
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>(305) 961-1155</v-list-item-title>
                    <v-list-item-subtitle>Phone</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action></v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>(786) 369-0459</v-list-item-title>
                    <v-list-item-subtitle>Fax</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">
                      mdi-email
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Sahr@dumbarlaw.com</v-list-item-title>
                    <v-list-item-subtitle>E-Mail</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item
                  href="https://www.google.com/maps/dir//1200+Brickell+Ave,+Miami,+FL+33131/@25.762004,-80.1945389,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88d9b681454fa31b:0x9290480a2de715df!2m2!1d-80.1923449!2d25.7619992"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon color="primary">
                      mdi-map-marker
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      1200 Brickell Ave. Suite 1950
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Miami, Florida 33131
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.2591024475655!2d-80.19453894917633!3d25.76200401479462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b681454fa31b%3A0x9290480a2de715df!2s1200%20Brickell%20Ave%2C%20Miami%2C%20FL%2033131!5e0!3m2!1sen!2sus!4v1633213753292!5m2!1sen!2sus"
                width="100%"
                height="100%"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="8">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="firstname"
                        :rules="firstnameRules"
                        required
                        label="First Name"
                        filled
                      />
                    </v-col>
                    <v-col  cols="12" md="6">
                      <v-text-field
                        v-model="lastname"
                        :rules="lastnameRules"
                        required
                        label="Last Name"
                        filled
                      />
                    </v-col>
                    <v-col  cols="12" md="6">
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        required
                        label="Email"
                        filled
                      />
                    </v-col>
                    <v-col  cols="12" md="6">
                      <v-text-field
                        v-model="phonenumber"
                        :rules="phonenumberRules"
                        required
                        label="Phone Number"
                        placeholder="555-555-5555"
                        filled
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="comments"
                        required
                        label="Comments"
                        filled
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <Captcha @verified="verified"/>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" large tile  @click="doEmail" :disabled="!validForm()">Send</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-snackbar
      v-model="snackbarSuccess"
    >
      <v-icon color="success" left>mdi-mailbox-up</v-icon>
      Your messege has been sent

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarFail"
    >
      <v-icon color="error" left>mdi-mailbox-up</v-icon>Error your messege not sent

      <template v-slot:action="{ attrs }">
        <v-btn
          color="error"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
  
</template>
<script>
import Captcha from "../components/Captcha.vue";
import { post } from "../services/util";

export default {
  name: "ContactUs",
  title: "Dumbar Law Contact Us",

  data: () => ({
    valid: false,
    firstname: "",
    firstnameRules: [
      (v) => !!v || "First Name is required",
      (v) =>
        (v && v.length <= 20) || "First Name must be less than 20 characters",
    ],
    lastname: "",
    lastnameRules: [
      (v) => !!v || "Last Name is required",
      (v) =>
        (v && v.length <= 20) || "Last Name must be less than 20 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phonenumber: "",
    phonenumberRules: [
      (v) => !!v || "Phone number is required",
      // (v) => /^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(v) || "Phone Number must be valid",
    ],
    comments:"",
    captcha_ver: true,
    captcha_token: "",
    snackbarSuccess: false,
    snackbarFail: false,
  }),
   methods: {
    doEmail() {
      post("/api/mail", {
        firstname: this.firstname,
        lastname: this.lastname,
        comments: this.comments,
        phonenumber: this.phonenumber,
        email: this.email
      }, { "captcha-token": this.captcha_token }).then(() => {
        this.firstname = "";
        this.lastname = "";
        this.comments = "";
        this.phonenumber = "";
        this.email = "";
        this.snackbarSuccess = true;
        this.captcha_ver = true;
      }).catch(e => {
        this.snackbarFail = true;
        console.log(e)
      });
    },
    verified(response) {
      this.captcha_ver = false;
      this.captcha_token = response;
      this.validForm();
    },
    validForm() {
      // debugger;
      if ( this.captcha_ver === true) {
        return false;
      } 
      return this.valid;
    }
  },
  components: {
    Captcha
  }

};
</script>
