<template>
  <v-container fluid>
    <v-row>
      <v-container>
        <v-row>
          <v-col class="mb-5" cols="12">
            <h2 class="text-h3 font-weight-bold mb-3">
              Sahr Dumbar
            </h2>
          </v-col>
          <v-col cols="12" md="auto">
            <v-img width="200" src="@/assets/img/photo-dumbar.png" />
          </v-col>
          <v-col>
            <v-card color="transparent" flat>
              <v-card-text class="body-1">
                <p>
                  Attorney Dumbar founded The Dumbar Law Center and is certified
                  by the Florida Supreme Court as a Mediator for Civil Circuit
                  and Residential Foreclosure cases. Mr. Dumbar has successfully
                  represented hundreds of clients in county court, state court,
                  federal court, and in mediation and arbitration proceedings.
                  Mr. Dumbar's practice areas include real estate transactions,
                  appeals, complex commercial matters, creation of business
                  entities and alternative dispute resolution services.
                </p>

                <p>
                  Attorney Dumbar is licensed to practice in the State of
                  Florida, Washington D.C., the 11th Circuit Court of Appeals
                  and the United States District Courts for the Southern, Middle
                  and Northern Districts of Florida. Mr. Dumbar has also
                  co-authored several articles in Construction INK! Magazine and
                  is a member of the Florida Bar Construction Law Committee, the
                  Miami-Dade County Bar Association and the Wilkie D. Ferguson
                  Jr. Bar Association.
                </p>

                <p>
                  Attorney Dumbar received his Bachelor of Science in Business
                  Administration degree, with honors, from the University of
                  Pittsburgh and his law degree from the University of Miami
                  School of Law where he received the prestigious Kozyak, Tropin
                  &amp; Throckmorton Scholarship award for Outstanding Skills in
                  Trial Advocacy.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Bio",
  title: "Dumbar Law Sahr's Bio",
  data: () => ({}),
};
</script>
