import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#1E3124',
            secondary: '#34423C',
            accent: '#BFFA00',
            link: '#009FFD',

            success: '#439775', 
            info: '#A40E4C',
            warning: '#FFA400', 
            error: '#DA2C38',

            paper: '#E1E1D4',
            dumbar_gray: '#BFACAA',
            dumbar_dark: '#171212'


          },
        },
      },
});
