<template>
  <v-app
    style="
    background: #E1E1D4;
  "
  >
    <v-app-bar app :color="$route.path !== '/' ? 'primary' : 'transparent' " dark flat >
      <div class="d-flex align-center">
        <!-- <v-app-bar-title>
          <v-img
            alt="Web With Us Icon"
            class="shrink mb-3"
            contain
            src="@/assets/svg/logo-dumbarlaw-accent.svg"
            transition="scale-transition"
            width="30"
          />
        </v-app-bar-title> -->
        <v-app-bar-title class="logo paper--text">
          The Dumbar Law Center
        </v-app-bar-title>
      </div>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="primaryNavigationBar = true" class="hidden-md-and-up" />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          depressed
          text
          :to="item.link"
          color="paper"
          v-for="item in primaryNavigation"
          :key="item.title"
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer
      v-model="primaryNavigationBar"
      dark
      app
      temporary
      class="gartek_black"
    >
      <v-list two-line>
        <v-list-item>
          <v-list-item-content
            ><v-img
              src="@/assets/svg/logo-dumbarlaw-accent.svg"
              contain
              height="70"
              width="140"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item
          to="/"
          active-class="link--text text--lighten-4 dumbar_dark lighten-1"
        >
          <v-list-item-icon>
            <v-icon>mdi-bank</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

       

        <v-list-item
          to="/practice-areas"
          active-class="link--text text--lighten-4 dumbar_dark lighten-1"
        >
          <v-list-item-icon>
            <v-icon>mdi-scale-balance</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Practice Areas</v-list-item-title>
        </v-list-item>

        <v-list-item
          to="/bio"
          active-class="link--text text--lighten-4 dumbar_dark lighten-1"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Bio</v-list-item-title>
        </v-list-item>

        <v-list-item
          to="/contact-us"
          active-class="link--text text--lighten-4 dumbar_dark lighten-1"
        >
          <v-list-item-icon>
            <v-icon>mdi-mailbox-up</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Contact</v-list-item-title>
        </v-list-item>

        
      </v-list>
    </v-navigation-drawer>
    <!-- <v-banner two-line sticky app dark color="secondary">
      <v-avatar slot="icon" color="accent" size="40">
        <v-icon icon="mdi-lock" color="white">
          mdi-phone
        </v-icon>
      </v-avatar>

      <span class="font-weight-black subtitle-1"
        >Call now for a consultation<br
      /></span>
      We are available for consultations throughout the Tri-County area.

      <template v-slot:actions>
        <span class="text-h4 mr-6 accent--text font-weight-black"
          >(305) 961-1151</span
        >
      </template>
    </v-banner> -->
    <v-main :style="$route.path !== '/' ? 'padding: 64px 0px 0px;' : 'padding: 0px 0px 0px;'" >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  
  data: () => ({
    //primary navigation
    primaryNavigation: [
      {
        title: "Home",
        link: "/",
      },
      {
        title: "Practice Areas",
        link: "/practice-areas",
      },
      {
        title: "Bio",
        link: "/bio",
      },
      {
        title: "Contact Us",
        link: "/contact-us",
      },
    ],
    primaryNavigationBar: false,
  }),
};
</script>
